.fondoGradianteLandingHeader {
     background: linear-gradient(180deg, rgba(38,52,64,1) 0%, rgba(34,35,35,1) 100%);
     color:#FFF;
}

.fondoGradianteLandingBody {
     min-height:100%;
     background: linear-gradient(180deg, rgba(38,52,64,1) 0%, rgba(34,35,35,1) 100%);
     background-position: 121.5% 152%;
     color:#FFF;
}

.espacioGradianteLandingCarousel {
     padding-top: 10px;
     background: linear-gradient(180deg, rgba(38,52,64,1) 0%, rgba(34,35,35,1) 100%);
     min-height: 95px;
}

.paddingLabelCarousel{
     padding: 0 8px 0 7.5px;
}

.textoSliderCarrousellLanding {
     font-size: 18px;
     font-style: normal;
     font-weight: 400;
     line-height: 28px; 
     color: #FFF;
}

.tituloLanding {
     font-size: 42px;
     font-style: normal;
     font-weight: 700;
     line-height: 52px;
}

.btnLandingLearner1 {
     color: #FFF;
     border-radius: 5px;
     background: linear-gradient(180deg, #F25836 0%, #DF0024 100%);
     font-size: 18px;
     font-style: normal;
     font-weight: 700;
     line-height: 28px;
     width: 100%;
     padding: 20px;
}

.btnLandingLearner2 {
     border-radius: 10px;
     background: linear-gradient(239deg, #F25836 9.62%, #DF0024 100%);
     font-size: 18px;
     font-style: normal;
     font-weight: 700;
     line-height: 28px;
     width: 339px;
     padding: 12px 20px;
     justify-content: center;
     align-items: center;
     gap: 10px;
     color: #FFF;
}

.checkMarkLandingLearner {
     -webkit-appearance: none;
     background-color: #B7B7B7 !important;
     width: 20px!important;
     height: 20px!important;
     border-style: none!important;
     margin-top: 5px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

.checkMarkLandingLearner:checked {
     background-color: #F25836 !important;
     color: white!important;
}

.checkMarkLandingLearner:checked:after {
	/* content: '\2714'; */
	font-size: 14px;
	position: absolute;
	top: -1.5px;
	left: 3.5px;
	color: white;
}

.circuloIconoHome {
     background: #D3D3D3;
     border-radius: 100%;
     width: 45px;
     height: 45px;
}

.textoLandingLearner1 {
     font-size: 24px;
     font-style: normal;
     font-weight: 700;
     line-height: 30px;
}

.textoLandingLearner2 {
     text-align: center;
     font-size: 30px;
     font-style: normal;
     font-weight: 400;
     line-height: 36px;
}

.textoLandingLearner3 {
     text-align: center;
     font-size: 30px;
     font-style: normal;
     font-weight: 700;
     line-height: 36px;
}

.textoLandingLearner4 {
     font-size: 18px;
     font-style: normal;
     font-weight: 400;
     line-height: 28px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span { 
     background: #F25836;
}

.owl-theme .owl-dots .owl-dot:hover span { 
     background: #D6D6D6;
}

.owl-theme .owl-dots .owl-dot span {
     background: #D6D6D6;
     width: 20px;
     height: 20px;
}

.owl-next i, .owl-prev i {
     color: #000000;
     font-size: 50px;
}

.sliderCorrousellIntroduccionLearner {
     /* width: 900px!important;
     height: 451px!important; */
}

.espacioNegroCarouselIntroduccionLearner {
     /* background-color: #F25836; */
     /* height: 518px; */
}

@media (max-width: 576px) {
     .col-sm-12 {
          flex: 0 0 auto;
          width: 100%;
     }

     .paddingLanding {
          padding: 10px !important;
     }
}
