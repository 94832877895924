/* @font-face {
  font-family:"basic-sans"!important;
  src:
    url("https://use.typekit.net/af/7008d7/00000000000000007735ac10/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff2"),
    url("https://use.typekit.net/af/7008d7/00000000000000007735ac10/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff"),
    url("https://use.typekit.net/af/7008d7/00000000000000007735ac10/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("opentype");
  font-display:auto;font-style:normal;font-weight:300;
}  */

body{
  overflow-x: hidden;
}

html, body{
  font-family:"basic-sans"!important;
}

.olineG {
  /* outline: 3px solid yellowgreen; */
}

.olineR {
  /* outline: 3px solid red; */
}

.olineB {
  /* outline: 3px solid blue; */
} 

.olineP {
  /* outline: 3px solid rgb(255, 0, 166); */
}