.divNegroPerfilJefe {
     background-color: rgb(0, 0, 0);
     border-radius: 10px;
     height: 39px;
}

.divBlancoPerfilJefe {
     background-color: #FFFFFF;
     border-radius: 10px;
}

.textoBlancoDivNegroPerfilJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 26px;
     color: #FFFFFF;
}

.textoNegroDivNegroPerfilJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 14px;
     color: #222323;
}

.textoVerdeDivNegroPerfilJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 28px;
     color: #218E8E;
}

.divBordePerfilJefe {
     border: 1px solid #B7B7B7;
     padding: 5px;
}

.franjaAmarillaPerfilJefe {
     background-color: #F6D300;
     height: 41px;
}

.textoFranjaAmarillaPerfilJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 24px;
     color: #222323;
}

.fechaMiCalendarioJefe {
     text-transform: uppercase;
}

.insigniasPerfilJefe {
     background-color: white;
     border-bottom-right-radius: 0.25rem !important;
     border-bottom-left-radius: 0.25rem !important;
     border-top-right-radius: 0 !important;
     border-top-left-radius: 0 !important;
     height: 96.4%!important;
}

.rowInsigniasPerfilJefe{
     height: 100%!important;
}