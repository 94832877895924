   .bodyCard {
     /* display: flex; */
     justify-content: center;
     align-items: center;
     /* height: 50vh; */
     margin: 0;
     margin-bottom: 10px;
   }
   
   .flipCard {
     width: 455px;
     height: 507px;
     perspective: 1000px;
   }
   
   .flipCard-inner {
     width: 100%;
     height: 100%;
     transform-style: preserve-3d;
     transition: transform 0.5s;
   }
   
   .flipCard.flipped .flipCard-inner {
     transform: rotateY(180deg);
   }
   
   .flipCard-front,
   .flipCard-back {
     width: 100%;
     height: 100%;
     position: absolute;
     backface-visibility: hidden;
   }
   
   .flipCard-front {
     /* background-color: #3498db; */
     display: flex;
     justify-content: center;
     align-items: center;
     font-size: 24px;
     color: #fff;
   }
   
   .flipCard-back {
     padding: 10px 10px 10px 10px;
     background-color: #EFEFEF;
     border-radius: 10px;
     /* display: flex; */
     /* justify-content: center;
     align-items: center; */
     /* font-size: 24px; */
     /* color: #fff; */
     transform: rotateY(180deg);
   }