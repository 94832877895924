.opcionesJefe {
     height: 50px;
     background-color: #E5E6E4;
}

.btn-menuJefe {
     color: black!important;
}

.paddingLabelCarousel{
     padding: 0 8px 0 7.5px;
}

.espacioGradianteLandingCarousel {
     padding-top: 10px;
     background: linear-gradient(180deg, rgba(38,52,64,1) 0%, rgba(34,35,35,1) 100%);
     min-height: 95px;
}

.textoMenuJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 28px;
     color: #5F5F5F;
}

.btn-menuJefe.active {
     color: #218E8E!important;
}

.linea-menuJefe.active {
     width: 100%;
     height: 3px;
     background-color: #DF0024;
}

.linea-menuRetroJefe.active {
     width: 100%;
     height: 3px;
     background-color: #F7C600;
}

.tituloVistaJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 42px;
     line-height: 52px;
}

.justificadoCentro {
     text-align: center;
}

.tituloRojoVistaJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 42px;
     line-height: 52px;
     color: #F00;
}

.parradoVistaJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 18px;
     line-height: 28px;
}

.parradoVistaJefe2 {
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 28px;
     padding: 10px 50px 20px 50px;
     text-align: center;
}

.parradoVistaJefe3 {
     font-size: 30px;
     font-style: normal;
     font-weight: 700;
     line-height: 36px;
}

.parradoVistaJefe4 {
     font-size: 24px;
     font-style: normal;
     font-weight: 700;
     line-height: 30px;
}

.textoVerdeIntroduccionJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 36px;
     color: #218E8E;
}

.textoVerdeIntroduccionJefe2 {
     font-style: normal;
     font-weight: 700;
     font-size: 42px;
     line-height: 52px;
     color: #218E8E;
}

.textoRojoIntroduccionJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 36px;
     color: #DF0024;
}

.textoNegroIntroduccionJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 18px;
     line-height: 28px;
     color: black;
}

.textoBlancoIntroduccionJefe {
     font-size: 30px;
     font-style: normal;
     font-weight: 400;
     line-height: 36px;
     color: white;
}

.iconoIntroduccion {
     font-size: xx-large;
     color: black;
}

.rowGrisIntroduccionJefe {
     border-radius: 5px;
     background-color: #EFEFEF;
     padding: 20px;
}

.btn-verdeJefe {
     background-color: #218E8E!important;
     border-radius: 10px!important;
     color: white!important;
     padding: 12px!important;
}

.gatoJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 36px;
     color: #F6D300;
}

.caminandoJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 36px;
     color: #DF0024;
}

.juntosJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 36px;
     color: black;
}

.textoRojoMisHerramientasJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 20px;
     line-height: 28px;
     color: #DF0024;
}

.lineaGrisMisHerramientasJefe {
     border-bottom: 3px solid rgba(183, 183, 183, 0.6);
     padding-bottom: 30px;
}

.iconoMisHerramientasJefe {
     font-size: xx-large;
     /* margin-right: 15px; */
}

.paddingIzqJefe {
     margin-left: 10px;
}

.cabezeraMatrizMisHerramientasJefe {
     background-color: #222323;
     padding: 20px;
     border-radius: 5px;
}

.herreamientaMisHerramientasJefe {
     margin-top: 15px;
     background: linear-gradient(180deg, #F25836 0%, #DF0024 100%);
     padding: 20px;
     border-radius: 5px;
}

.herreamientaDeshabilitadaMisHerramientasJefe {
     margin-top: 15px;
     background: linear-gradient(180deg, #F25836 0%, #DF0024 100%);
     padding: 20px;
     border-radius: 5px;
}

.unidadMatrizMisHerramientasJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 20px;
     line-height: 26px;
     color: white;
}

.nombreUnidadMatrizMisHerramientasJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 20px;
     line-height: 26px;
     color: white;
}

.textoBtnMatrizMisHerramientasJefe {
     font-size: 18px;
     font-style: normal;
     font-weight: 700;
     line-height: 28px;
     color: white;
}

.textoBoldBtnMatrizMisHerramientasJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 22px;
     color: #000000;
     text-align: center;
}

.divGrisMatrizMisHerramientas {
     margin-top: 15px;
     background: linear-gradient(180deg, #F25836 0%, #DF0024 100%);
     padding: 10px;
     border-radius: 5px;
}

.textoBtnGrisMatrizMisHerramientasJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 22px;
     margin-bottom: 10px;
     color: white;
}

.labelHeaderJefe {
     background-color: #F6D300;
     border-radius: 5px;
     width: 87px;
     height: 23px;
}

.textoLabelHeaderJefe {
     margin-top: 2px;
     font-style: normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 20px;
     color: #5F5F5F;
}

.fotoUsuariosJefe {
     border-radius: 50%;
     object-fit: cover;
}

th.encabezadoGrisUsuariosJefe {
     background-color: #263440!important;
     color: white;
}

th.encabezadoAmarilloUsuariosJefe {
     background-color: #F7C600!important;
}

th.encabezadoVerdeUsuariosJefe {
     background-color: #218E8E!important;
}

td.prepEvalUsuariosJefe {
     background-color: rgba(247, 198, 0, 0.5);
     color: #263440;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 21px;
     text-decoration: underline;
     cursor: pointer;
}

td.envEvalUsuariosJefe {
     background-color: #263440;
     color: white;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 21px;
     text-decoration: underline;
     cursor: pointer;
}

td.calEvalUsuariosJefe {
     color: #F25836;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 21px;
     text-decoration: underline;
     cursor: pointer;
}

button.linkPrepararTablaAvanceJefe {
     color: #F25836;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 21px;
     text-decoration: underline;
}

button.linkRojoPrepararTablaAvanceJefe {
     color: #DF0024;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 21px;
     text-decoration: underline;
}

.textoGrisTablaJefe {
     color: #5F5F5F!important;
     font-style: normal!important;
     font-weight: 400!important;
     font-size: 16px!important;
     line-height: 21px!important;
}

.textoGrisPromedioTablaJefe {
     color: #5F5F5F!important;
     font-style: normal!important;
     font-weight: 700!important;
     font-size: 16px!important;
     line-height: 21px!important;
}

a.linkTablaAvanceJefe:hover {
     color: #000000;
     text-decoration: underline;
}

.toolbarTextoVerdeUsuariosJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 26px;
     line-height: 28px;
     color: #F25836;
     margin-right: 50px;
}

.toolbarTextoFechaVerdeUsuariosJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 28px;
     color: #5F5F5F;
     margin-right: 50px;
}

.toolbarTextoDivUsuariosJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 21px;
     color: #FFFFFF;
}

.btn-negro {
     background-color: #020203!important;
     border-radius: 5px;
     width: 350px;
     height: 45px;
}

.usuariosPendientesPorCalificarDivIconoJefe {
     color: white;
     font-size: medium;
}

.divAmarilloUsuarioRetroJefe{
     background-color: #F7C600;
     padding: 10px;
     border-radius: 5px;
}

.textoDivAmarilloUsuarioRetroJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 42px;
     line-height: 52px;
     color: #020203;
}

.divNegroUsuarioRetroJefe {
     background-color: #020203;
     border-radius: 5px;
}

.textoEncabezadoRetroJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 18px;
     line-height: 28px;
     color: #000000;
}

.cuadroAmarilloLetraRetroJefe {
     background-color: #F7C600;
}

.cuadroGrisLetraRetroJefe {
     background-color: #C4C4C4;
}

.letraRetroJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 22px;
     line-height: 24px;
     color: #FFFFFF;
}

.letraTextoRetroJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 24px;
     color: #000000;
}

.divApartadoRetroJefe {
     padding: 5px;
}

.tituloUnidadFormRetroJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 28px;
     color: #DF0024;
}

.parrafoEncabezadoFormRetroJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 28px;
     color: #000000;
}

.tituloAcordeonRetroJefe {
     font-size: 20px;
     font-style: normal;
     font-weight: 400;
     line-height: 26px;
     color: #000000;
     text-transform: uppercase;
}

.textoEncabezadoSeccionFormRetroJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 26px;
     color: #000000;
}

.textoPreguntaFormRetroJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 24px;
     color: #000000;
}

.labelRespuestaFormRetroJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 26px;
     color: #000000;
}

.checkMarkFormRetroJefe:checked {
     background-color: #F25836 !important;
}

.checkMarkFormRetroJefe:checked ~ label {
     color: #000000;
}

.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
     opacity: unset!important;
}

.btn-grisFormRetroJefe {
     background-color: #F5F7F4!important;
     color: #F25836!important;
     border-radius: 10px!important;
     font-style: normal!important;
     font-weight: 700!important;
     font-size: 18px!important;
     line-height: 28px!important;
}

.btn-verdeFormRetroJefe {
     background: linear-gradient(180deg, #F25836 0%, #DF0024 100%)!important;
     color: white!important;
     border-radius: 10px!important;
     font-style: normal!important;
     font-weight: 700!important;
     font-size: 18px!important;
     line-height: 28px!important;
}

div.divAmarilloAlertaCompromisosJefe {
     /* background: rgba(247, 198, 0, .5); */
     padding: 20px;
     border-radius: 5px;
}

.iconoAlertaCompromisosJefe {
     color: #DF0024;
     font-size: xx-large;
     margin-top: 10px;
}

.textoAlertaComprososJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 36px;
     color: #DF0024;
}

.textoRojoInstruccionesCompromisosJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 18px;
     line-height: 28px;
     color: #DF0024;
}

.textoNegroInstruccionesCompromisosJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 18px;
     line-height: 28px;
     color: #000000;
}

.TextoAcuerdoInputFormCompromisosJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 28px;
     color: #000000;
}

textarea.textareaJefe {
     background-color: #E5E6E4;
 }

 .checkMarkUnidadJefe {
     width: 35.62px;
     height: 30px;
     background-color: #DEDEDE;
 }

 td>div.paddingCelda {
     height: 100%;
     margin-top: 30px;
 }

 .checkMarkUnidadJefe:checked {
     background-color: #F25836 !important;
     color: white!important;
     border-color: #F25836;
}

tr.headerTablaModulosJefe {
     background-color: #263440;
     color: white;
}

tr.rowTablaModulosJefe {
     background-color: rgba(196, 196, 196, 0.25);
}

tr .align-me {
     display: flex;
     align-items: center;
}

td.celdaVerdeTablaRetoJefe {
     background-color: #F25836;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 28px;
     color: white;
}

td.celdaVerdeTablaRetoJefe>i {
     font-size: large;
}

td.celdaAmarillaTablaRetoJefe {
     background-color: #F7C600;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 28px;
     color: #5F5F5F;
}

td.celdaRojaTablaRetoJefe {
     background-color: #DF0024;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 28px;
     color: white;
}

td.celdaRojaTablaRetoJefe>i {
     font-size: large;
}

.divEspecialDetallesJefe {
     width: 471px!important;
     height: 124px!important; 
     color: white;
     border-radius: 10px;
}

.tituloUnidadDetallesJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 28px;
     color: #020203;
}

.nombreUnidadDetallesJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 28px;
     color: #F00;
}

.fechaInicioUnidadDetallesJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 28px;
     color: #5F5F5F;
}

.subtitulosDetallesJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 28px;
     color: #020203;
}

.headerTablasDetallesJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 18px;
     color: #FFFFFF;
}

.botonesDetallesTituloEspecial {
     font-style: normal;
     font-weight: 700;
     font-size: 20px;
     line-height: 26px;
     color: #FFFFFF;
}

.linkVerdeRetoDetallesJefe {
     color: #F25836;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 28px;
     text-decoration: underline;
}

.linkVerdeRetoDetallesJefe:hover {
     color: #F25836;
     text-decoration: underline;
}

.linkGrisRetoDetallesJefe {
     color: #5F5F5F;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 28px;
     text-decoration: none;
}

.linkGrisRetoDetallesJefe:hover {
     color: #5F5F5F;
     text-decoration: none;
}

.linkRojoRetoDetallesJefe {
     color: #DF0024;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 28px;
     text-decoration: underline;
     
}

.linkRojoRetoDetallesJefe:hover {
     color: #DF0024;
     text-decoration: underline;
     
}

.dropdown>span.caret {
     color: white;
}

.react-bootstrap-table-pagination-list>.pagination>.active.page-item>a {
     background-color: #F25836;
     color: white;
}

.react-bootstrap-table-pagination-list>.pagination>.page-item>a {
     background-color: white;
     color: #F25836;
}

.fechaEntregaRetoUnidadDetallesJefe {
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 28px;
     color: #5F5F5F;
}

.btn-verdeModalJefe {
     background: linear-gradient(180deg, #F25836 0%, #DF0024 100%)!important;
     color: #EEEDED!important;
     border-radius: 10px!important;
     height: 52px!important;
     font-style: normal!important;
     font-weight: 700!important;
     font-size: 18px!important;
     line-height: 28px!important;
}

.btn-verdeAcuerdosModalJefe {
     background: linear-gradient(180deg, #F25836 0%, #DF0024 100%)!important;
     color: #EEEDED!important;
     border-radius: 10px!important;
     width: 150px;
     height: 52px!important;
     font-style: normal!important;
     font-weight: 700!important;
     font-size: 18px!important;
     line-height: 28px!important;
}

.btn-grisModalJefe {
     background-color: #FAFAFA!important;
     color: #F25836!important;
     width: 150px;
     border-radius: 10px!important;
     height: 52px!important;
     font-style: normal!important;
     font-weight: 700!important;
     font-size: 18px!important;
     line-height: 28px!important;
}

.textoVerdeModalJefe {
     font-style: normal;
     font-weight: 400;
     font-size: 30px;
     line-height: 36px;
     color: #000000;
}

.cardBodyAcordeonDetallesJefeLearner {
     background-color: white;
}

.imagenObscura:before {
     content:'';
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0;
     background-color: rgba(0,0,0,0.6);
 }

 .owl-item .item.unidad:hover {
     -ms-transform: scale(1.1);
     -moz-transform: scale(1.1); 
     -webkit-transform:scale(1.1);
     transform:scale(1.1);
     border-radius: 15px;
     z-index: 99999;
}

.owl-stage-outer {
     padding: 26px;
     left: -26px;
     margin-right: -52px;
 }

 .contenidoItemCentrado {
     height: 100%!important;
}

.owl-prev {
     width: 15px;
     height: 100px;
     position: absolute;
     top: 40%;
     margin-left: 0px;
     display: block !important;
     border:0px solid black;
     z-index: 2;
 }

 .owl-prev i {
     color: #DEDEDE;
     font-size: 50px;
 }

 .owl-next {
     width: 15px;
     height: 100px;
     position: absolute;
     top: 40%;
     right: 10px;
     display: block !important;
     border:0px solid black;
     z-index: 2;
 }

 .owl-next:hover {
      background-color: none;
 }

 .owl-next i {
     color: #DEDEDE;
     font-size: 50px;
}

button.owl-next:hover, 
button.owl-prev:hover{
     color: #218E8E!important;
     background-color: transparent!important;
}

button.owl-next.disabled, 
button.owl-prev.disabled{
     color: transparent!important;
     background-color: transparent!important;
}

button.owl-next.disabled i, 
button.owl-prev.disabled i{
     color: transparent!important;
     background-color: transparent!important;
}

.item1SliderJefe {
     background-image: url("../../../assets/img/Slider_jefe1.jpg");
     width: 934px;
     height: 450px;
     background-repeat: no-repeat;
     background-size: cover;
}
.item2SliderJefe {
     background-image: url("../../../assets/img/Slider_jefe2.jpg");
     width: 934px;
     height: 450px;
     background-repeat: no-repeat;
     background-size: cover;
}
.item3SliderJefe {
     background-image: url("../../../assets/img/Slider_jefe3.jpg");
     width: 934px;
     height: 450px;
     background-repeat: no-repeat;
     background-size: cover;
}
.item4SliderJefe {
     background-image: url("../../../assets/img/Slider_jefe4.jpg");
     width: 934px;
     height: 450px;
     background-repeat: no-repeat;
     background-size: cover;
}
.item5SliderJefe {
     background-image: url("../../../assets/img/Slider_jefe5.jpg");
     width: 934px;
     height: 450px;
     background-repeat: no-repeat;
     background-size: cover;
}

.espacioNegroCarouselIntroduccionJefe {
     background-color: #F25836;
     height: 518px;
}

.espacioGrisCarouselIntroduccionJefe {
     padding-top: 10px;
     background-color: #C4C4C4;
     min-height: 95px;
}

.textoJefeCentrado {
     text-align: center;
}

.sliderCorrousellIntroduccionJefe {
     width: 766px!important;
     height: 451px!important;
}

.textoSliderCarrousellIntroduccionJefe {
     text-align: center;
     font-style: normal;
     font-weight: 400;
     font-size: 18px;
     line-height: 28px;
     color: #1A1A1A;
}

.etiquetaRubrica {
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 28px;
     color: #000000;
}

.accordion > .cardAcordeonIntroJefe {
     background-color: #F6D300 !important;
}

.cardAcordeonIntroJefe {
     background-color: #F6D300 !important;
}

.cardAcordeonIntroJefe > .card-header {
     border-bottom: none !important;
}

.tituloAcordeonIntroJefe {
     color: #1A1A1A;
     font-size: 30px;
     font-style: normal;
     font-weight: 700;
     line-height: 36px;
}

.btn-acordeonIntroJefe > div > div.flechaAcordeonDiv > img.flechaAcordeon{
     transform: rotate(180deg) !important;
     transition: transform 0.3s linear !important;
}

.btn-acordeonIntroJefe.collapsed > div > div.flechaAcordeonDiv > img.flechaAcordeon{
     transform: rotate(0deg) !important;
     transition: transform 0.3s linear !important;
}

.textoAcordeonIntroJefe {
     font-size: 18px;
     font-style: normal;
     font-weight: 400;
     line-height: 28px;
     color: #1A1A1A;
}

.fondoGradianteLandingJefe {
     /* min-height:100%; */
     background: linear-gradient(180deg, rgba(38,52,64,1) 0%, rgba(34,35,35,1) 100%);
     /* background-position: 121.5% 152%; */
     color:#FFF;
}

.flipcard1 {
     width: 455px;
     height: 507px;
     padding: 24px;
     border-radius: 10px;
     background-image: url(../../../assets/img/Flipcard_jefe1.jpg);
     background-size: 455px;
}

.flipcard2 {
     width: 455px;
     height: 507px;
     padding: 24px;
     border-radius: 10px;
     background-image: url(../../../assets/img/Flipcard_jefe2.jpg);
     background-size: 455px;
}

.flipcardShow {
     width: 455px;
     padding: 24px;
     border-radius: 10px;
     background: #EFEFEF;
}

.tituloFlipCard {
     font-size: 30px;
     font-style: normal;
     font-weight: 700;
     line-height: 36px;
     color: #1A1A1A;
}

.puntosFlipCard {
     color: #1A1A1A;
}

.btnLandingJefe {
     font-size: 14px;
     font-style: normal;
     font-weight: 700;
     line-height: 20px; 
     padding: 6px 24px;
     border: none;
     color: #fff;
     border-radius: 5px;
     background: linear-gradient(180deg, #F25836 0%, #DF0024 100%);
}




/*
************************************************
 INICIO RESPONSIVO 
************************************************
*/

/* 
MEDIA QUERIES
Medida de tabletas y móviles 
Se deja como max 767, ya que boostrap tiene sus medias queries como min 768, lo que quiere decir que cambia a partie de 767
*/
@media (max-width: 767px){
     .sliderCorrousellIntroduccionJefe {
          width: 444px!important;
          height: 262px!important;
      }
      .table {
          font-size:13px;
      }
      td.calEvalUsuariosJefe {          
          font-size: 13px;          
      }
      button.linkPrepararTablaAvanceJefe {          
          font-size: 13px;
      }      
}
@media (min-width: 768px) and (max-width: 991px){
     .sliderCorrousellIntroduccionJefe {
          width: 613px!important;
          height: 361px!important;
      }
      .table {
          font-size:13px;
      }
      td.calEvalUsuariosJefe {          
          font-size: 13px;          
      }
      button.linkPrepararTablaAvanceJefe {          
          font-size: 13px;
      }
      .cabezeraMatrizMisHerramientasJefe {
          min-height: 160px;
      }
      .herreamientaMisHerramientasJefe {
          min-height: 170px;
      }
      .herreamientaDeshabilitadaMisHerramientasJefe {
          min-height: 170px;
      }
}
@media (min-width: 992px) and (max-width: 1200px){
     .sliderCorrousellIntroduccionJefe {
          width: 650px!important;
          height: 383px!important;
      }
      .cabezeraMatrizMisHerramientasJefe {
          min-height: 160px;
      }
      .herreamientaMisHerramientasJefe {
          min-height: 170px;
      }
      .herreamientaDeshabilitadaMisHerramientasJefe {
          min-height: 170px;
      }      
}
