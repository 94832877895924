.btn-verdeAviso {
     /* background-color: #218E8E!important; */
     border-color: none!important;
     font-size: 18px!important;
     font-weight: 700!important;
     color: #FFFFFF!important;
}

.btn-verdeAviso>i {
     color: #FFFFFF!important;
}

.aireabajo {
     margin-bottom: 3rem;
}

.masFont {
     font-size: x-large;
}
.linkMail {
     text-decoration: none;
     color: #000;
}

.btnAvisoPrivacidad {
     width: 339px !important;
     font-size: 18px !important;
     font-style: normal !important;
     font-weight: 700 !important;
     line-height: 28px !important;  
     color: #FFF !important;
     background: linear-gradient(180deg, #F25836 0%, #DF0024 100%) !important;
}