.brand-link .brand-image {
     float: none!important;
}

.brand-link {
     border-bottom: none !important;
}

.tituloModuloAdmin {
     font-style: normal;
     font-weight: 700;
     font-size: 30px;
     line-height: 36px;
     color: #222323;
     margin-left: 10px;
}

.card-administrador {
     margin-top: 10px;
     background-color: #FFFFFF;
}

.card-administradorAnaliticos {
     margin-top: 10px;
     background-color: #F4F4F4;
}

.textoModuloAdministrador {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 26px;
     color: #000000;
}

.ajusteTextoModuloAdministrador {
     margin-top: 10px;
     padding: 10px 10px 10px 10px;
}

.btn-verdeLift {
     background: #218E8E !important;
     border-radius: 5px !important;
     font-style: normal !important;
     font-weight: 700 !important;
     font-size: 18px !important;
     line-height: 28px !important;
     color: #FFFFFF !important;
}

.btn-grisLift {
     background: #B7B7B7 !important;
     border-radius: 5px !important;
     font-style: normal !important;
     font-weight: 700 !important;
     font-size: 18px !important;
     line-height: 28px !important;
     color: #FFFFFF !important;
}

.btn-LiftCeja {
     background: #218E8E !important;
     border-radius: 5px !important;
     font-style: normal !important;
     font-weight: 700 !important;
     font-size: 14px !important;
     line-height: 20px !important;
     color: #FFFFFF !important;
     width: 212px !important;
     height: 30px !important;
     padding: 0px !important;
}

 .uppercase {
     text-transform: uppercase;
     font-style: normal;
     font-weight: 700;
     font-size: 12px;
     line-height: 24px;
 }

 #table .btn-tablaAdmin1 {
     background-color: #4D4C4C;
     border-radius: 5px;
     color: #FFFFFF;
     margin: 0px 5px 0px 5px;
 }

 .btn-cejaAdmin1 {
     font-size: x-small!important;
     background-color: #4D4C4C;
     border-radius: 5px;
     color: #FFFFFF!important;
     margin: 0px 5px 0px 5px;
     border: none;
     width: 27.32px;
     height: 28px;
 }

 #table .btn-tablaAdmin2 {
     background-color: #222323;
     border-radius: 5px;
     color: #FFFFFF;
     margin: 0px 5px 0px 5px;
 }

.btn-cejaAdmin2 {
     font-size: x-small!important;
     background-color: #222323;
     border-radius: 5px;
     color: #FFFFFF!important;
     margin: 0px 5px 0px 5px;
     width: 27.32px;
     height: 28px;
 }

.custom-control-input:checked~.custom-control-label::before {
     border-color: #218E8E !important;
     background-color: #218E8E !important;
}

.btn-Lift {
     background-color: #218E8E !important;
     color: #FFFFFF !important;
     font-style: normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 20px;
}

.btn-secondaryLiftAdmin {
     background-color: #FAFAFA !important;
     color: #218E8E !important;
     font-style: normal;
     font-weight: 700;
     font-size: 14px;
     line-height: 20px;
     border: 1px solid #E5E6E4;
     text-transform: uppercase;
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 28px;
}

.tituloModalForm {
     font-style: normal !important;
     font-weight: 700 !important;
     font-size: 30px !important;
     line-height: 36px !important;
     color: #222323 !important;
}

.checkMarkLift {
     -webkit-appearance: none;
     background-color: #E5E5E5 !important;
     width: 19px!important;
     height: 16px!important;
     border-style: none!important;
     margin-top: 5px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

.checkMarkLift:checked {
     background-color: #218E8E !important;
     color: white!important;
}

.checkMarkLift:checked:after {
	content: '\2714';
	font-size: 14px;
	position: absolute;
	top: -1.5px;
	left: 3.5px;
	color: white;
}

.marginRigth10{
     margin-right: 10px;
}

.paddingTop10 {
     padding-top: 10px;
     margin-bottom: 15px;
}

.inputAdminLift {
     background-color: #D9D9D9 !important;
     border-radius: 5px;
}

.modal-dialog.modal-90w {
     min-width: 90% !important;
}

.tooltip { 
     z-index: 1151 !important; 
}

.boldAdminTxt {
     font-weight: 700;
}

.rowActive {
     background-color: #218E8E !important;
}

.ajusteMenuAdmin {
     --bs-gutter-x: 0rem !important;
     margin-left: -4px !important;
}

.ajusteMenuAdmin2 {
     overflow-y: auto !important; 
}

.fondoNegroAdmin {
     background-color: #000000;
     padding: 5px 5px 5px 5px;
}

.form-check-input-reverse{
     margin-left: 10px;
     width: 25px!important;
     height: 25px!important;
     background-color: #FFFFFF!important;
}

.form-check-input-form{
     margin-left: 10px;
     width: 25px!important;
     height: 25px!important;
     background-color: #D9D9D9!important;
}

.form-check-input-reverse.checkMarkLift:checked:after {
	content: '\2714'!important;
	font-size: 16px!important;
	position: absolute!important;
	top: 2px!important;
	left: 5px!important;
	color: white!important;
}

.form-check-input-form.checkMarkLift:checked:after {
	content: '\2714'!important;
	font-size: 16px!important;
	position: absolute!important;
	top: 2px!important;
	left: 5px!important;
	color: white!important;
}

.labelCheckLiftAdmin {
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 24px;
     color: #000000;
}

.select-lift {
     display: block;
     width: 100%;
     height: calc(2.25rem + 2px);
     padding: 0.375rem 0.75rem;
     font-size: 1rem;
     font-weight: 400;
     line-height: 1.5;
     color: #495057;
     background-clip: padding-box;
     border: 1px solid #ced4da;
     border-radius: 0.25rem;
     box-shadow: inset 0 0 0 rgb(0 0 0 / 0%);
     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.textoUploaderAdmin {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 26px;
     color: #666666;
}

.linkSpanLiftAdmin {
     color: #218E8E;
     text-decoration: underline;
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 26px;
}

.textoVariableUploaderAdmin {
     font-style: normal;
     font-weight: 400;
     font-size: 12px;
     line-height: 15px;
     color: #888888;
}

.celdaTablaAdmin {
     font-style: normal;
     font-weight: 400;
     font-size: 16px;
     line-height: 26px;
     color: #000000;
}

.pagination > li > a
{
    background-color: white;
    color: #000000;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover
{
    color: #5a5a5a;
    background-color: #eee;
    border-color: #ddd;
}

.pagination > .active > a
{
    color: white;
    background-color: #000000 !important;
    border: solid 1px #000000 !important;
}

.pagination > .active > a:hover
{
    background-color: #000000 !important;
    border: solid 1px #000000;
}

.btn-secondary {
     color: #fff;
     background-color: #000000 !important;
     border-color: #000000 !important;
     box-shadow: none;
 }

 .tarjetaVerdeArbolAdmin {
     background-color: #218E8E !important;
 }

 .textoSeccionFormModalAdmin {
     font-style: normal;
     font-weight: 700;
     font-size: 24px;
     line-height: 30px;
     color: #222323;
 }

 .react-datepicker-popper {
     width: 200px !important;
 }

 .react-datepicker__time-container  {
     width: 200px !important;
 }

 .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
     width: 200px !important;
 }

 .tituloSubseccionAdmin {
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 28px;
     color: #222323;
 }

 .txtMenuSideAdmin {
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 28px;
     color: #FFFFFF;
 }

 .btnGrisBloqueadoArbol {
     background-color: #B7B7B7 !important;
 }

 .listadoTarjetasArbol {
     background-color: #D9D9D9;
     min-width: 40px;
     text-align: center;
     padding: 10px 0px 10px 0px;
 }

 .MuiSvgIcon-root {
     fill: currentColor;
     width: 1em;
     height: 1em;
     display: inline-block;
     font-size: 1.5rem;
     transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
     flex-shrink: 0;
     user-select: none;
     position: absolute;
     right: 15px;
     top: 51px;
 }

 .espacioBlancoAdmin {
     width: 40px !important;
 }

 .ajusteAlturaHeader {
     max-height: 51px!important;
     min-height: 51px!important;
 }

