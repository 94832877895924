.notificacion {
     /* height: 100px; */
     width: 450px;
}

.notificacion>div {
     height: 100%;
}

.notiPregunta {
     border-left: 6px solid #218E8E !important;
}

.notiReto {
     border-left: 6px solid #DF0024 !important;
}

.notiSesion {
     border-left: 6px solid #DF0024 !important;
}

.dropdown-menu-xl {
     max-width: 500px !important;
}

.tituloNotificacion {
     font-style: normal;
     font-weight: bold;
     font-size: 14px;
     line-height: 20px;
}

.fechaNotificacion {
     color: #DF0024 !important;
     font-style: normal;
     font-weight: normal;
     font-size: 10px;
     line-height: 12px;
     padding-top: 2px;
}

.textoNotificacion {
     font-style: normal;
     font-weight: normal;
     font-size: 14px;
     line-height: 20px;
}

.divNotificaciones {
	overflow-y: scroll;
     max-height: 400px;
     overflow-x: hidden;
}

.ajustarCerrarNotificacion {
     height: 100%;
     margin-left: 10px;
}