.root {
  margin: 0 auto;
}

.loadingPage{
  position: fixed;
  background-color: black;
  height: 100vh;
  width: 100vw;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999;
}
.loading {
  width: 140px;
  height: 140px;
}