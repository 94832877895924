.regresar {
     background-color: #5F5F5F;
     padding-bottom: 0px;
     margin-bottom: 0px;
     -ms-flex: 0 0 234px;
     flex: 0 0 234px;
}

.regresar>a>i {
     color: #F6D300;
     margin-right: 1rem;
}

.regresar>a>span {
     color: #FFFFFF;
     margin-right: 1rem;
}

.breadcrumbItem {
     text-decoration: none;
     color: #000000;
     font-size: 12px;
     line-height: 20px;
     font-style: normal;
}

.breadcrumbSeparator {
     color: #DF0024;
}

/*
************************************************
 INICIO RESPONSIVO 
************************************************
*/

/* 
MEDIA QUERIES
Medida de tabletas y móviles 
Se deja como max 767, ya que boostrap tiene sus medias queries como min 768, lo que quiere decir que cambia a partie de 767
*/
@media (max-width: 767px){
     .regresar {
          background-color: #5F5F5F;
          padding-bottom: 0px;
          margin-bottom: 0px;
          flex: 0 0 123px;
     }
}